import React, {Component} from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import withTracker from "./withTracker";

import LandingPage from "./pages/LandingPage";
import ScrollToTop from "./pages/ScrollToTop";
import SignInScreen from "./pages/SignInScreen";
import CompleteUserProfileScreen from "./pages/CompleteUserProfileScreen";
import EventDetailScreen from "./pages/EventDetailScreen";
import EventFormScreen from "./pages/EventForm/EventFormScreen";
import UserProfileScreen from "./pages/UserProfileScreen";

class App extends Component {
  render() {
    return (
      <Router>
        <ScrollToTop>
          <div>
            <Switch>
              <Route exact path="/" component={withTracker(LandingPage)} />
              <Route path="/signin" component={withTracker(SignInScreen)} />
              <Route
                path="/completeprofile"
                component={withTracker(CompleteUserProfileScreen)}
              />
              <Route
                path="/eventdetail"
                component={withTracker(EventDetailScreen)}
              />
              <Route
                path="/eventform"
                component={withTracker(EventFormScreen)}
              />
              <Route
                path="/userprofile"
                component={withTracker(UserProfileScreen)}
              />
            </Switch>
          </div>
        </ScrollToTop>
      </Router>
    );
  }
}

export default App;
