import {combineReducers} from "redux";
import AuthReducer from "./AuthReducer";
import UserProfileReducer from "./UserProfileReducer";
import FireStorageReducer from "./FireStorageReducer";

export default combineReducers({
  auth: AuthReducer,
  user: UserProfileReducer,
  fireStorage: FireStorageReducer,
});
