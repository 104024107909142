/**
 *
 * @format
 * @flow
 */

import React, {Component} from "react";
import {H2, H3, Icon} from "native-base";
import {IoMdCalendar, IoIosPin} from "react-icons/io";
import moment from "moment";
import {Row} from "../common/";

const TAG = "EventDetailScreen: ";

export default class EventDetailScreen extends Component {
  constructor(props) {
    super(props);
    console.log(TAG + "Constructor");
  }

  componentDidMount() {
    const {eventAddressLine2} = this.props.location.state;
    console.log(TAG + "eventName:", eventAddressLine2);
  }

  render() {
    const {state} = this.props.location;

    return (
      <div
        style={{
          width: "100%",
          background: "#f2f2f2",
          minHeight: "100vh",
          paddingTop: "10px",
        }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            background: "#fff",
            maxWidth: "440px",
            margin: "auto",
            paddingBottom: "30px",
            marginTop: "10px",
            padding: "15px 30px",
          }}>
          <div>
            <img
              style={{
                width: "100%",
                flex: 1,
              }}
              alt={"event "}
              src={
                state.imageDownloadUrl
                  ? state.imageDownloadUrl
                  : require("../img/addPicturePlaceHolder.png")
              }
            />
          </div>

          <div style={styles.cardSection}>
            <H2 style={styles.infoText}>{state.eventName}</H2>
          </div>

          <div style={styles.cardSection}>
            <IoMdCalendar size={20} />
            <H3 style={[styles.infoText, {marginLeft: 10}]}>
              {moment(state.eventStartDate).format("dddd, MMM Do")}
              {/*Start Date*/}
            </H3>
            <Row>
              <H3 style={[styles.infoText, {paddingLeft: 25}]}>
                {moment(state.eventStartDate).format("hh:mm a")}
              </H3>
              <H3
                style={[styles.infoText, {paddingLeft: 10, paddingRight: 10}]}>
                -
              </H3>
              <H3 style={styles.infoText}>
                {moment(state.eventEndDate).format("hh:mm a")}
              </H3>
            </Row>
          </div>

          <div style={styles.cardSection}>
            <IoIosPin style={styles.infoText} size={20} />
            <H3 style={[styles.infoText, {marginLeft: 10}]}>
              {state.eventVenue}
            </H3>
            <Row>
              <H3 style={[styles.infoText, {paddingLeft: 25}]}>
                {state.eventAddressLine1}
              </H3>
            </Row>

            <Row>
              <H3 style={[styles.infoText, {paddingLeft: 25}]}>
                {state.eventAddressLine2}
              </H3>
            </Row>
          </div>

          <div style={styles.cardSection}>
            <H2 style={styles.infoText}>Description</H2>
            <p style={styles.eventDescription}>{state.eventDescription}</p>
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  infoText: {
    fontWeight: "400",
    color: "#47525E",
  },

  eventDescription: {
    textAlign: "left",
    padding: "2%",
  },

  cardSection: {
    paddingTop: 30,
    paddingLeft: 20,
    paddingBottom: 30,
    borderBottom: "1px solid #ddd",
  },
};
