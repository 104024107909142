import firebase from "firebase";

const config = {
  apiKey: "AIzaSyDFvu8vSgxLyyfVlZgzDoyquncfLo9gmEw",
  authDomain: "dapdap-77192.firebaseapp.com",
  databaseURL: "https://dapdap-77192.firebaseio.com",
  projectId: "dapdap-77192",
  storageBucket: "dapdap-77192.appspot.com",
  messagingSenderId: "946367640327",
  appId: "1:946367640327:web:19d523a43b79dfe4",
};

let firestoreDB;

const getFirestoreDB = () => {
  if (!!firestoreDB) {
    return firestoreDB;
  }

  if (!firebase.apps.length) {
    firebase.initializeApp(config);
  }

  firestoreDB = firebase.firestore();
  return firestoreDB;
};

export default getFirestoreDB;
