import {
  EMAIL_CHANGED,
  LOGIN_USER_SUCCESS,
  PASSWORD_CHANGED,
  LOGIN_USER_FAIL,
  LOGIN_USER,
  LOGIN_USER_ANONYMOUSLY,
  CONFIRM_PASSWORD_CHANGED,
  CREATE_USER_ACCOUNT,
  CREATE_USER_ACCOUNT_SUCCESS,
  CREATE_USER_ACCOUNT_FAIL,
  CLEAR_DATA,
} from "../actions/AuthTypes";

const INITIAL_STATE = {
  email: "",
  password: "",
  confirmPassword: "",
  user: null,
  error: "",
  loading: false,
};

export default (state = INITIAL_STATE, action) => {
  console.log(action);

  switch (action.type) {
    case CLEAR_DATA:
      return {...state, error: ""};

    case EMAIL_CHANGED:
      return {...state, email: action.payload};

    case PASSWORD_CHANGED:
      return {...state, password: action.payload};

    case CONFIRM_PASSWORD_CHANGED:
      return {...state, confirmPassword: action.payload};

    case CREATE_USER_ACCOUNT:
      return {...state, loading: true, error: ""};

    case CREATE_USER_ACCOUNT_SUCCESS:
      return {...state, ...INITIAL_STATE, user: action.payload};

    case CREATE_USER_ACCOUNT_FAIL:
      return {...state, error: action.payload, loading: false};

    case LOGIN_USER:
      return {...state, loading: true, error: ""};

    case LOGIN_USER_ANONYMOUSLY:
      return {...state};

    case LOGIN_USER_SUCCESS:
      return {...state, ...INITIAL_STATE, user: action.payload};

    case LOGIN_USER_FAIL:
      return {...state, error: action.payload, loading: false};

    default:
      return state;
  }
};
