import {
  USERNAME_CHANGED,
  USER_PROFILE_DESCRIPTION_CHANGED,
  SET_FIELD_IN_DB,
  SET_FIELD_IN_DB_SUCCESS,
  SET_FIELD_IN_DB_FAIL,
} from "./UserProfileTypes";

const TAG = "AuthActions.js";

export const userNameChanged = (text) => {
  return {
    type: USERNAME_CHANGED,
    payload: text,
  };
};

export const userProfileDescriptionChanged = (text) => {
  return {
    type: USER_PROFILE_DESCRIPTION_CHANGED,
    payload: text,
  };
};

export const setFieldInDB = (CollectionRef, docRef, fieldKey, fieldValue) => {
  return (dispatch) => {
    dispatch({type: SET_FIELD_IN_DB});

    if (!fieldValue.length) {
      let fieldName;
      switch (fieldKey) {
        case "userName":
          fieldName = "Profile Name";
          break;

        case "userProfileDescription":
          fieldName = "Profile Description";
          break;

        case "eventOrganiserImage":
          fieldName = "Profile Image";
          break;

        default:
          fieldName = "undefined";
          break;
      }
      setFieldInDbFail(dispatch, fieldName + " cannot be empty");
      return;
    }

    //set with merge
    CollectionRef.doc(docRef)
      .set(
        {
          [fieldKey]: fieldValue,
          userFirebaseUid: docRef,
        },
        {merge: true},
      )
      .then(() => {
        console.log(TAG + "setFieldInDB: Document successfully written!");
        setFieldInDbSuccess(dispatch);
      })
      .catch(function(error) {
        console.error(TAG + "setFieldInDB Error writing document: ", error);
        setFieldInDbFail(dispatch, error);
      });
  };
};

const setFieldInDbSuccess = (dispatch) => {
  dispatch({
    type: SET_FIELD_IN_DB_SUCCESS,
    payload: true,
  });
};

const setFieldInDbFail = (dispatch, error) => {
  dispatch({
    type: SET_FIELD_IN_DB_FAIL,
    payload: error,
  });
};
