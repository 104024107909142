import React, {Component} from "react";
import {Link} from "react-router-dom";
//import {Thumbnail, H1, H2, H3} from "native-base";
//import {MaterialIcons, Feather} from "@expo/vector-icons";
import greenStatus from "../../../src/img/greenStatus.png";
import redStatus from "../../../src/img/redStatus.png";
import {MdEdit, MdClear} from "react-icons/md";
import {FaXing} from "react-icons/fa";
import {Card, CardSection} from "../index";
import moment from "moment";

const EventCard = (props) => {
  return (
    <div
      style={{
        borderBottom: "15px solid #f2f2f2",
      }}>
      <CardSection
        style={{
          flexDirection: "row",
          alignItems: "center",

          borderBottomWidth: 1,
          //paddingRight: 5,
          //          paddingTop: 25,
          //paddingBottom: 25,
          backgroundColor: "#fff",
          justifyContent: "flex-start",
          //borderColor: "#ddd",
          //border: "1px solid blue",
        }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            flex: 1,
            //border: "1px solid red",
            padding: 10,
          }}>
          <img
            style={{
              height: "40px",
              width: "40px",
              borderRadius: "60px",
              //border: "1px solid red",
            }}
            alt={"user profile image"}
            src={
              props.event.eventOrganiserImage
                ? props.event.eventOrganiserImage
                : require("../../img/userprofileplaceholderimage.png")
            }
          />

          <div
            style={{
              flexWrap: "wrap",
              flexDirection: "row",
              flex: 1,
            }}>
            <p
              style={{
                paddingLeft: 20,
                fontWeight: "400",
                color: "#47525E",
              }}>
              {props.event.userName}
            </p>
          </div>

          <div>
            {/*{!!props.openEditEvent && (*/}
            <MdEdit
              onClick={() => props.openEditEvent()}
              style={{paddingStart: 10, paddingEnd: 10}}
              name="edit"
              size={40}
              color="#9E9E9E"
            />
            {/*)}*/}
            {/*{!!this.props.openDeleteEventAlert && (*/}
            <MdClear
              name={"x"}
              size={40}
              style={{paddingStart: 10, paddingEnd: 10}}
              color="#9E9E9E"
              onClick={() => props.openDeleteEventAlert()}
            />
            {/*)}*/}
          </div>
        </div>
      </CardSection>

      <Link
        style={{textDecoration: "none"}}
        to={{
          pathname: "/eventdetail",
          state: {
            imageDownloadUrl: props.event.imageDownloadUrl,
            eventName: props.event.eventName,
            eventStartDate: props.event.eventStartDate,
            eventEndDate: props.event.eventEndDate,
            eventVenue: props.event.eventVenue,
            eventAddressLine1: props.event.eventAddressLine1,
            eventAddressLine2: props.event.eventAddressLine2,
            eventDescription: props.event.eventDescription,
          },
        }}>
        {/*Event Image Section*/}
        <CardSection
          style={{
            //marginLeft: 10,

            flexDirection: "row",
            alignItems: "center",

            //paddingRight: 5,
            //paddingLeft: 0,
            //paddingTop: 25,
            //paddingBottom: 25,
            backgroundColor: "#fff",
            justifyContent: "flex-start",
            //borderColor: "#ddd",
            //border: "1px solid red",
          }}>
          <img
            style={{
              flex: 1,
              width: "100%",
              height: "auto",
            }}
            //resizeMode={"stretch"}
            alt={"eventImage"}
            src={
              props.event.imageDownloadUrl === null
                ? require("../../../src/img/addPicturePlaceHolder.png")
                : props.event.imageDownloadUrl
            }
          />
        </CardSection>
        {/*Event Card Footer*/}

        {/*Event Card Footer*/}
        <div
          style={{
            // backgroundColor: "red",
            display: "flex",

            flex: 1,
            flexDirection: "row",
          }}>
          {/*Event Card Footer Left*/}
          <div
            style={{
              borderRightWidth: 1,
              //borderColor: "red",
              display: "flex",
              borderRight: "1px solid #cecece",
              flexDirection: "column",
              alignItems: "center",
              paddingTop: 20,
              paddingBottom: 20,
              paddingRight: 5,
              marginTop: 10,
              marginBottom: 10,
            }}>
            <h3
              style={{
                textAlign: "center",
                fontWeight: "400",
                color: "#47525E",
              }}>
              {/*{isMayoDayEvent(this.props.event)}*/}
              {moment(props.event.eventStartDate).format("ddd")}
            </h3>
            <h3
              style={{
                textAlign: "center",
                fontWeight: "400",
                color: "#47525E",
              }}>
              {" "}
              -{" "}
            </h3>
            <h3
              style={{
                fontWeight: "800",
                textAlign: "center",
                color: "#47525E",
              }}>
              {moment(props.event.eventStartDate).format("Do MMM")}
            </h3>
          </div>
          {/*Event Card Footer Right*/}
          <div
            style={{
              display: "flex",

              flex: 4,
              flexDirection: "column",
            }}>
            {/*Event Name*/}
            <div
              style={{
                flex: 1,
                borderBottom: "1px solid #cecece",
                padding: 15,
                paddingTop: 15,
              }}>
              <h3 style={{fontWeight: "400", color: "#47525E"}}>
                {props.event.eventName}
              </h3>
            </div>

            {/*Event Card Footer Right Time & Address*/}
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "row",
                // borderWidth: 1,
                // borderColor: "red",

                paddingTop: 10,
                paddingBottom: 10,
              }}>
              {/*Event Card Footer Time*/}
              <div
                style={{
                  flex: 1,
                  borderRightWidth: 1,
                  borderColor: "#ddd",
                  marginTop: 10,
                  marginBottom: 10,
                  textAlign: "center",
                  padding: 10,
                }}>
                {/*if mm != 00 print expressions */}
                {moment(props.event.eventStartDate).format("mm") === "00" ? (
                  <p
                    style={{
                      textAlign: "center",
                      fontWeight: "400",
                      color: "#47525E",
                    }}>
                    {moment(props.event.eventStartDate).format("h a")}
                  </p>
                ) : (
                  <p
                    style={{
                      textAlign: "center",
                      fontWeight: "400",
                      color: "#47525E",
                    }}>
                    {moment(props.event.eventStartDate).format("h:mm a")}
                  </p>
                )}
              </div>
              <div style={{flex: 4, paddingTop: 10, paddingBottom: 10}}>
                <p
                  style={{
                    textAlign: "center",
                    fontWeight: "400",
                    color: "#47525E",
                  }}>
                  {props.event.eventVenue}
                </p>
                <p
                  style={{
                    textAlign: "center",
                    fontWeight: "400",
                    color: "#47525E",
                  }}>
                  {props.event.eventAddressLine1},{" "}
                  {props.event.eventAddressLine2}
                </p>
              </div>
            </div>
          </div>
        </div>
      </Link>
      <CardSection
        style={{
          flexDirection: "row",
          alignItems: "center",
          borderBottomWidth: 1,
          backgroundColor: "#fff",
          justifyContent: "flex-start",
        }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            flex: 1,
            //border: "1px solid red",
            padding: 10,
          }}>
          <div style={{textAlign: "center"}}>
            <img
              style={{
                height: "40px",
                width: "40px",
                borderRadius: "60px",
                //border: "1px solid red",
              }}
              alt={"event status indicator"}
              src={
                props.event.eventStatus === "published"
                  ? greenStatus
                  : redStatus
              }
            />
            <p>
              {props.event.eventStatus === "published"
                ? "Visible"
                : "Not Visible"}
            </p>
          </div>

          {/*<div*/}
          {/*style={{*/}
          {/*flexWrap: "wrap",*/}
          {/*flexDirection: "row",*/}
          {/*flex: 1,*/}
          {/*}}>*/}
          {/*<p*/}
          {/*style={{*/}
          {/*paddingLeft: 20,*/}
          {/*fontWeight: "400",*/}
          {/*color: "#47525E",*/}
          {/*}}>*/}
          {/*Views: 102 | More Info: 10 | Book Now: 5*/}
          {/*</p>*/}
          {/*</div>*/}
        </div>
      </CardSection>
    </div>
  );
};

export default EventCard;

// const styles = StyleSheet.create({
//   eventCardHeader: {
//     flexDirection: "row",
//     justifyContent: "flex-start",
//     alignItems: "center",
//     paddingTop: 10,
//     paddingBottom: 10,
//   },
//   eventOrganiserImage: {
//     marginLeft: 10,
//   },
//
//   eventPromoterName: {
//     paddingLeft: 20,
//     fontWeight: "400",
//     color: "#47525E",
//   },
//
//   eventImage: {
//     paddingTop: 0,
//     paddingBottom: 0,
//     paddingLeft: 0,
//     paddingRight: 0,
//   },
//
//   Text: {
//     textAlign: "center",
//     fontWeight: "400",
//     color: "#47525E",
//   },
//
//   eventCardFooter: {
//     flex: 1,
//     flexDirection: "row",
//     height: 175,
//     // backgroundColor: "red",
//     borderBottomWidth: 0,
//   },
//
//   eventCardFooterLeft: {
//     flex: 1,
//     flexDirection: "column",
//     alignItems: "center",
//     justifyContent: "center",
//     //backgroundColor: "green",
//     borderBottomWidth: 0,
//     borderRightWidth: 1,
//   },
//
//   eventCardFooterRight: {
//     flex: 4,
//     flexDirection: "column",
//     justifyContent: "space-between",
//     backgroundColor: "orange",
//     borderBottomWidth: 0,
//   },
//
//   eventCardEventName: {
//     flex: 1,
//     alignItems: "flex-start",
//     marginTop: 10,
//     marginBottom: 10,
//   },
//
//   eventCardTimeAddress: {
//     flexDirection: "row",
//     borderBottomWidth: 0,
//   },
//
//   eventCardTime: {
//     flex: 1,
//     borderBottomWidth: 0,
//     borderRightWidth: 1,
//   },
//
//   eventAddress: {
//     flex: 4,
//     flexDirection: "column",
//     borderBottomWidth: 0,
//   },
// });
