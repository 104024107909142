import {
  UPLOAD_IMAGE_TO_STORAGE,
  UPLOAD_IMAGE_TO_STORAGE_SUCCESS,
  UPLOAD_IMAGE_TO_STORAGE_FAIL,
} from "../actions/FireStorageTypes";

const INITIAL_STATE = {
  loading: false,
  imageUploadSuccess: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPLOAD_IMAGE_TO_STORAGE:
      return {...state, loading: true};

    case UPLOAD_IMAGE_TO_STORAGE_FAIL:
      return {...state, error: action.payload, loading: false};

    case UPLOAD_IMAGE_TO_STORAGE_SUCCESS:
      return {
        ...state,
        imageDownloadUrl: action.payload,
        imageUploadSuccess: true,
        loading: false,
      };

    default:
      return state;
  }
};
