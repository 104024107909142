import {
  USERNAME_CHANGED,
  USER_PROFILE_DESCRIPTION_CHANGED,
  SET_FIELD_IN_DB,
  SET_FIELD_IN_DB_SUCCESS,
  SET_FIELD_IN_DB_FAIL,
} from "../actions/UserProfileTypes";

const INITIAL_STATE = {
  userName: "",
  user: null,
  success: false,
  error: "",
  loading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case USERNAME_CHANGED:
      return {...state, userName: action.payload};

    case USER_PROFILE_DESCRIPTION_CHANGED:
      return {...state, userProfileDescription: action.payload};

    case SET_FIELD_IN_DB:
      return {...state, loading: true, error: ""};

    case SET_FIELD_IN_DB_SUCCESS:
      return {...state, success: true, loading: false};

    case SET_FIELD_IN_DB_FAIL:
      return {...state, error: action.payload, loading: false};

    default:
      return state;
  }
};
