/**
 *
 * @format
 * @flow
 */

import React from "react";
import {
  Container,
  Header,
  Content,
  Form,
  Item,
  Text,
  //Label,
  Picker,
} from "native-base";
import {
  Input,
  TextArea,
  Button,
  Dimmer,
  Segment,
  Loader,
  Dropdown,
  Accordion,
  Icon,
} from "semantic-ui-react";
import "./eventForm.css";

import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import uuid from "uuid";
//import DateTimePicker from "react-native-modal-datetime-picker";

import * as firebase from "firebase";
import getFirestoreDB from "../../firebase/firestore/getFirestoreDb";
import {GeoCollectionReference, GeoDocumentReference} from "geofirestore";

const db = getFirestoreDB();
const eventCollectionRef = db.collection("events");
const geoFirestoreCollection = new GeoCollectionReference(eventCollectionRef);

let Filter = require("bad-words"),
  filter = new Filter();

const options = [
  {
    key: "music",
    text: "Music",
    value: "music",
  },
  {
    key: "foodAndDrink",
    text: "Food & Drink",
    value: "foodAndDrink",
  },
  {
    key: "theatre",
    text: "Theatre",
    value: "theatre",
  },
  {
    key: "charitiesAndCauses",
    text: "Charities & Causes",
    value: "charitiesAndCauses",
  },
  {
    key: "talk",
    text: "Talk",
    value: "talk",
  },
  {
    key: "healthAndWellbeing",
    text: "Health & Wellbeing",
    value: "healthAndWellbeing",
  },
  {
    key: "art",
    text: "Art",
    value: "art",
  },
  {
    key: "family",
    text: "Family",
    value: "family",
  },
  {
    key: "comedy",
    text: "Comedy",
    value: "comedy",
  },
  {
    key: "sportsAndFitness",
    text: "Sports & Fitness",
    value: "sportsAndFitness",
  },
  {
    key: "other",
    text: "Other",
    value: "other",
  },
];

const TAG = "EventFormScreen: ";

export default class EventFormScreen extends React.Component {
  constructor(props) {
    super(props);
    console.log(TAG + "Constructor");

    this.inputRefs = {};

    this.state = {
      loading: false,
      loadingMessage: "Please Wait",
      eventKey: db.collection("events").doc().id,
      eventTimeStamp: moment().format("llll"),
      eventName: "",
      eventStartDate: moment()
        .add(2, "hours")
        .startOf("hour")
        .format("llll"),
      eventEndDate: moment()
        .add(2, "hours")
        .startOf("hour")
        .format("llll"),
      eventEndRepeatDate: moment()
        .add(1, "hours")
        .startOf("hour")
        .format("llll"),
      eventRepeatFrequency: "never",
      eventVenue: "",
      eventAddressLine1: "",
      eventAddressLine2: "",
      eventTicketsLink: "",
      eventMoreInfoLink: "",
      eventDescription: "",
      isStartDateTimePickerVisible: false,
      isEndDateTimePickerVisible: false,
      isRepeatDateTimePickerVisible: false,
      pickedImage: "",
      eventImageName: "",
      imageDownloadUrl: "",
      editMode: false,
      userName: "",
      eventOrganiserImage: "",
      eventIsFeatured: false,
      eventCategory: "Music",
      activeIndex: 0,
    };
  }

  componentWillMount() {
    console.log(TAG + "componentWillMount");

    let {state} = this.props.location;
    console.log(TAG + "userName:", state.userName);
    console.log(TAG + "eventDate: " + state.eventStartDate);

    console.log(TAG + "editMode: ", state.editMode);

    this.setState({
      userName: state.userName,
      eventOrganiserImage: state.eventOrganiserImage,
    });

    // if (state.eventCategory !== undefined) {
    //   const defaultCategories = options.filter((o) =>
    //     state.eventCategory.includes(o.value),
    //   );
    // }

    if (state.editMode) {
      this.setState({
        eventName: state.eventName,
        eventKey: state.eventKey,
        eventVenue: state.eventVenue,
        eventAddressLine1: state.eventAddressLine1,
        eventAddressLine2: state.eventAddressLine2,
        eventCategory: state.eventCategory,
        //todo check if below ternary statement is necessary

        // eventCategory: state.eventCategory.map
        //   ? state.eventCategory
        //   : [state.eventCategory],
        eventTicketsLink: state.eventTicketsLink,
        eventMoreInfoLink: state.eventMoreInfoLink,
        eventDescription: state.eventDescription,
        eventStartDate: state.eventStartDate,
        eventEndDate: state.eventEndDate,
        pickedImage: state.imageDownloadUrl,
        imageDownloadUrl: state.imageDownloadUrl,
        eventImageName: state.eventImageName,
        eventIsFeatured: state.eventIsFeatured ? state.eventIsFeatured : false,
        userFirebaseUID: state.userFirebaseUid,
        eventTimeStamp: moment().format("llll"),
        editMode: state.editMode,
      });

      console.log("eventName: ", this.state.eventName);
    }
  }

  render() {
    const eventCategory = this.state.eventCategory;
    const defaultCategories = options.filter((o) =>
      eventCategory.includes(o.value),
    );

    return (
      <Dimmer.Dimmable as={Segment} dimmed={this.state.loading}>
        <Dimmer active={this.state.loading} inverted>
          <Loader>{this.state.loadingMessage}</Loader>
        </Dimmer>
        <div
          style={{
            width: "100%",
            background: "#f2f2f2",
            minHeight: "100vh",
            paddingTop: "20px",
          }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              //margin: "3% 3%",
              background: "#fff",
              maxWidth: "440px",
              margin: "auto",
              paddingBottom: "30px",
              height: "100%",
              marginTop: "10px",
            }}>
            <Form
              style={{
                width: "100%",
                padding: "20px",
              }}>
              <div
                style={{
                  width: "100%",
                }}>
                <label htmlFor="eventImage">
                  <img
                    style={{
                      width: "100%",
                      flex: 1,
                      //borderRadius: "60px",
                    }}
                    alt={"event image"}
                    src={
                      this.state.pickedImage
                        ? this.state.pickedImage
                        : require("../../img/addPicturePlaceHolder.png")
                    }
                  />

                  <input
                    type="file"
                    name="eventImage"
                    id="eventImage"
                    accept={"image/*"}
                    onChange={this.selectImageHandler}
                    style={{display: "none"}}
                  />
                </label>
              </div>

              <div
                style={{
                  //flex: "1",
                  flexDirection: "row",
                  justifyContent: "center",
                  paddingTop: "30px",
                  // alignSelf: "stretch",
                }}>
                <label style={styles.infoText}>
                  <Text style={{color: "red"}}>* </Text>
                  Event Name
                  <input
                    type="text"
                    //placeholder={"Event Name"}
                    value={this.state.eventName}
                    onChange={(text) => this._setEventName(text)}
                    maxLength={50}
                    style={{
                      // borderRadius: "5%",
                      fontSize: "20",
                      fontStyle: "light",
                      height: "30px",
                      marginTop: "10px",
                      paddingLeft: "10px",
                      borderRadius: "4px",
                      border: "1px solid silver",
                    }}
                  />
                </label>
              </div>

              <div style={{paddingTop: "30px"}}>
                <Text style={styles.text}>
                  <Text style={{color: "red"}}>* </Text> Start Date
                </Text>
                <DatePicker
                  selected={moment(this.state.eventStartDate).toDate()}
                  onChange={this.handleStartDatePicked}
                  dateFormat={"dd, MMM, yyyy h:mm a"}
                  minDate={moment().toDate()}
                  showTimeSelect
                  withPortal
                  disabledKeyboardNavigation
                />
              </div>

              <div style={{paddingTop: "30px"}}>
                <Text style={styles.text}>
                  <Text style={{color: "red"}}>* </Text> End Date
                </Text>
                <DatePicker
                  selected={moment(this.state.eventEndDate).toDate()}
                  onChange={this.handleEndDatePicked}
                  dateFormat={"dd, MMM, yyyy h:mm a"}
                  minDate={moment(this.state.eventStartDate).toDate()}
                  showTimeSelect
                  withPortal
                  disabledKeyboardNavigation
                />
              </div>

              {/*<Accordion styled>*/}
              {/*<Accordion.Title index={0} onClick={this.handleClick}>*/}
              {/*<Icon name="dropdown" />*/}
              {/*What is a dog?*/}
              {/*</Accordion.Title>*/}
              {/*<Accordion.Content>*/}
              {/*<p>*/}
              {/*A dog is a type of domesticated animal. Known for its*/}
              {/*loyalty and faithfulness, it can be found as a welcome guest*/}
              {/*in many households across the world.*/}
              {/*</p>*/}
              {/*</Accordion.Content>*/}
              {/*</Accordion>*/}

              <div
                style={{
                  //flex: "1",
                  flexDirection: "row",
                  justifyContent: "center",
                  paddingTop: "30px",
                  // alignSelf: "stretch",
                }}>
                <label style={styles.infoText}>
                  <Text style={{color: "red"}}>* </Text>
                  Venue
                  <input
                    type="text"
                    //placeholder={"Event Name"}
                    value={this.state.eventVenue}
                    onChange={(text) => this.setEventVenue(text)}
                    maxLength={25}
                    style={{
                      // borderRadius: "5%",
                      fontSize: "20",
                      fontStyle: "light",
                      height: "30px",
                      marginTop: "10px",
                      paddingLeft: "10px",
                      borderRadius: "4px",
                      border: "1px solid silver",
                    }}
                  />
                </label>
              </div>

              <div
                style={{
                  //flex: "1",
                  flexDirection: "row",
                  justifyContent: "center",
                  paddingTop: "30px",
                  // alignSelf: "stretch",
                }}>
                <label style={styles.infoText}>
                  <Text style={{color: "red"}}>* </Text>
                  Address Line 1
                  <input
                    type="text"
                    //placeholder={"Event Name"}
                    value={this.state.eventAddressLine1}
                    onChange={(text) => this.setEventAddressLine1(text)}
                    maxLength={25}
                    style={{
                      // borderRadius: "5%",
                      fontSize: "20",
                      fontStyle: "light",
                      height: "30px",
                      marginTop: "10px",
                      paddingLeft: "10px",
                      borderRadius: "4px",
                      border: "1px solid silver",
                    }}
                  />
                </label>
              </div>

              <div
                style={{
                  //flex: "1",
                  flexDirection: "row",
                  justifyContent: "center",
                  paddingTop: "30px",
                  // alignSelf: "stretch",
                }}>
                <label style={styles.infoText}>
                  <Text style={{color: "red"}}>* </Text>
                  Address Line 2
                  <input
                    type="text"
                    //placeholder={"Event Name"}
                    value={this.state.eventAddressLine2}
                    onChange={(text) => this.setEventAddressLine2(text)}
                    maxLength={25}
                    style={{
                      // borderRadius: "5%",
                      fontSize: "20",
                      fontStyle: "light",
                      height: "30px",
                      marginTop: "10px",
                      paddingLeft: "10px",
                      borderRadius: "4px",
                      border: "1px solid silver",
                    }}
                  />
                </label>
              </div>

              <div style={{paddingTop: "30px"}}>
                <Text style={styles.infoText}>
                  <Text style={{color: "red"}}>* </Text>
                  Category
                </Text>
                <Item picker style={{marginTop: "15px"}}>
                  <Picker
                    mode="dropdown"
                    style={{width: undefined}}
                    placeholder="Select a category"
                    // placeholderStyle={{color: "#bfc6ea"}}
                    selectedValue={this.state.eventCategory}
                    onValueChange={(value) => this.setEventCategory(value)}>
                    <Picker.Item label="Music" value="Music" />
                    <Picker.Item label="Food & Drink" value="Food & Drink" />
                    <Picker.Item label="Theatre" value="Theatre" />
                    <Picker.Item
                      label="Charities & Causes"
                      value="Charities & Causes"
                    />
                    <Picker.Item label="Talk" value="Talk" />
                    <Picker.Item
                      label="Health & Wellbeing"
                      value="Health & Wellbeing"
                    />
                    <Picker.Item label="Art" value="Art" />
                    <Picker.Item label="Family" value="Family" />
                    <Picker.Item label="Comedy" value="Comedy" />
                    <Picker.Item
                      label="Sports & Fitness"
                      value="Sports & Fitness"
                    />
                    <Picker.Item label="Other" value="Other" />
                  </Picker>
                </Item>
                {/*<Dropdown*/}
                {/*placeholder={"Please select a category..."}*/}
                {/*fluid*/}
                {/*multiple*/}
                {/*selection*/}
                {/*onChange={(e, {value}) => this.setEventCategory(value)}*/}
                {/*defaultValue={defaultCategories.map((dC) => dC.value)}*/}
                {/*options={options}*/}
                {/*/>*/}
              </div>

              {/*<Input*/}
              {/*style={{paddingTop: "30px"}}*/}
              {/*placeholder={"Event Tickets Link (Optional)"}*/}
              {/*value={this.state.eventTicketsLink}*/}
              {/*onChange={(text) => this.setEventTicketsLink(text)}*/}
              {/*maxLength={80}*/}
              {/*/>*/}

              <div
                style={{
                  //flex: "1",
                  flexDirection: "row",
                  justifyContent: "center",
                  paddingTop: "30px",
                  // alignSelf: "stretch",
                }}>
                <label style={styles.infoText}>
                  Event Tickets Link (Optional)
                  <input
                    type="text"
                    placeholder={"if free event leave blank"}
                    value={this.state.eventTicketsLink}
                    onChange={(text) => this.setEventTicketsLink(text)}
                    maxLength={120}
                    style={{
                      // borderRadius: "5%",
                      fontSize: "20",
                      fontStyle: "light",
                      height: "30px",
                      marginTop: "10px",
                      borderRadius: "4px",
                      border: "1px solid silver",
                      paddingLeft: "10px",
                    }}
                  />
                </label>
              </div>

              <div
                style={{
                  //flex: "1",
                  flexDirection: "row",
                  justifyContent: "center",
                  paddingTop: "30px",
                  // alignSelf: "stretch",
                }}>
                <label style={styles.infoText}>
                  More Info Link (Optional)
                  <input
                    type="text"
                    placeholder={"www.example.com"}
                    value={this.state.eventMoreInfoLink}
                    onChange={(text) => this.setEventMoreInfoLink(text)}
                    maxLength={120}
                    style={{
                      // borderRadius: "5%",
                      fontSize: "20",
                      fontStyle: "light",
                      height: "30px",
                      marginTop: "10px",
                      borderRadius: "4px",
                      border: "1px solid silver",
                      paddingLeft: "10px",
                    }}
                  />
                </label>
              </div>

              {this.renderModeratorFields()}

              <div style={{paddingTop: 30}}>
                <Form style={{paddingTop: 10}}>
                  <TextArea
                    style={{
                      fontStyle: "light",
                      height: "100px",
                      marginTop: "10px",
                      width: "100%",
                      borderRadius: "4px",
                      border: "1px solid silver",
                      fontSize: "16px",
                    }}
                    placeholder="Event Description"
                    value={this.state.eventDescription}
                    onChange={(text) => this.setEventDescription(text)}
                    maxLength={3000}
                  />
                </Form>
              </div>

              <Button
                style={{
                  // border: "solid red",
                  textDecoration: "none",
                  padding: "15px 35px",
                  background: "#f47332",
                  color: "white",
                  fontWeight: "500",
                  borderRadius: "4px",
                  marginTop: "20px",
                  fontSize: "20px",
                }}
                onClick={this._uploadEvent}>
                Submit
              </Button>
            </Form>
          </div>
        </div>
      </Dimmer.Dimmable>
    );
  }

  _setEventName(event) {
    this.setState({eventName: event.target.value});
    console.log("eventName: ", this.state.eventName);
    this.detectExplicitText(event.target.value, "eventName");
  }

  setEventVenue(event) {
    this.setState({eventVenue: event.target.value});
    console.log("eventVenue: ", this.state.eventVenue);
    this.detectExplicitText(event.target.value, "eventVenue");
  }

  setEventAddressLine1(event) {
    this.setState({eventAddressLine1: event.target.value});
    console.log("eventAddressLine1: ", event.target.value);
    this.detectExplicitText(event.target.value, "eventAddressLine1");
  }

  setEventAddressLine2(event) {
    this.setState({eventAddressLine2: event.target.value});
    console.log("eventAddressLine2: ", event.target.value);
    this.detectExplicitText(event.target.value, "eventAddressLine2");
  }

  setEventCategory(eventCategory) {
    this.setState({eventCategory: eventCategory});
    console.log("setEventCategory: eventCategory: ", this.state.eventCategory);
  }

  setEventTicketsLink(event) {
    this.setState({eventTicketsLink: event.target.value});
    console.log("eventTicketsLink: ", this.state.eventTicketsLink);
  }

  setEventMoreInfoLink(event) {
    this.setState({eventMoreInfoLink: event.target.value});
    console.log("eventMoreInfoLink: ", this.state.eventMoreInfoLink);
  }

  setEventDescription(event) {
    this.setState({eventDescription: event.target.value});
    console.log(
      "setEventDescription: eventDescription: ",
      this.state.eventDescription,
    );
    this.detectExplicitText(event.target.value, "eventDescription");
  }

  showStartDateTimePicker = () =>
    this.setState({isStartDateTimePickerVisible: true});

  hideStartDateTimePicker = () =>
    this.setState({isStartDateTimePickerVisible: false});

  _formatDate = (date) => {
    const momentDate = moment(date).format("llll");
    console.log("momentDate: " + momentDate);
    return momentDate;
  };

  handleStartDatePicked = (date) => {
    console.log("handleStartDatePicked: A start date has been picked: ", date);
    let formattedDate = this._formatDate(date);
    let formattedEndDate = moment(formattedDate)
      .add(2, "hours")
      .format("llll");

    this.setState({
      eventStartDate: formattedDate,
      eventEndDate: formattedEndDate,
    });
  };

  handleEndDatePicked = (date) => {
    console.log("handleEndDate A date has been picked: ", date);
    let formattedDate = this._formatDate(date);
    this.setState({eventEndDate: formattedDate});
    console.log("eventEndDate: " + this.state.eventEndDate);
  };

  handleClick = (e, titleProps) => {
    const {index} = titleProps;
    const {activeIndex} = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({activeIndex: newIndex});
  };

  detectExplicitText(string, textFieldName) {
    if (filter.isProfane(string)) {
      console.log(TAG + "filter.clean()", filter.clean(string));
      console.log(TAG + "filter.isProfane(): ", filter.isProfane(string));
      this.setState({
        explicitTextDetected: true,
        explicitTextFieldName: textFieldName,
      });
    } else {
      this.setState({explicitTextDetected: false});
    }
  }

  selectImageHandler = (event) => {
    let selectedImage = event.target.files[0];
    console.log(TAG + "selected Event Image: ", selectedImage);
    if (!selectedImage) {
      alert("Error picking image please try again");
      //todo do try catch here with sentry exception
      return;
    }

    this.setState({
      pickedImage: URL.createObjectURL(selectedImage),
    });

    try {
      this.uploadFile(selectedImage);
    } catch (error) {
      console.error("selectImageHandler error: " + error);
    }
  };

  uploadFile = (file) => {
    const storageRef = firebase
      .storage()
      .ref(
        "users/" +
          firebase.auth().currentUser.uid +
          "/" +
          "userEvents/" +
          this.state.eventKey +
          "/" +
          file.name,
      );

    let task = storageRef.put(file);
    task.on(
      "state_changed",

      function progress(snapshot) {},

      function(error) {
        console.log("Fire storage upload error: ", error);
      },

      () => {
        console.log("Fire storage upload Successful!");

        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        task.snapshot.ref.getDownloadURL().then((downloadURL) => {
          console.log("File available at", downloadURL);

          this.setState({
            imageDownloadUrl: downloadURL,
            eventImageName: file.name,
          });
        });
      },
    );
  };

  _uploadEvent = async () => {
    console.log("_uploadEvent Called");
    this.setState({
      loading: true,
    });

    if (this.state.explicitTextDetected) {
      let explicitTextFieldName;
      switch (this.state.explicitTextFieldName) {
        case "eventName":
          explicitTextFieldName = "Event Name";
          break;
        case "eventVenue":
          explicitTextFieldName = "Event Venue";
          break;
        case "eventAddressLine1":
          explicitTextFieldName = "Event Address Line 1";
          break;
        case "eventAddressLine2":
          explicitTextFieldName = "Event Address Line 2";
          break;
        case "eventDescription":
          explicitTextFieldName = "Event Description";
          break;

        default:
          explicitTextFieldName = "above text fields";
      }

      alert(
        "Explicit Text Detected:" +
          "Explicit text has been detected in " +
          explicitTextFieldName +
          " please remove it",
      );

      this.setState({
        loading: false,
      });
      return;
    }

    console.log(
      "uploadEvent: this.state.imageDownloadUrl: ",
      this.state.imageDownloadUrl,
    );
    if (this.state.pickedImage.length === 0) {
      alert("Please select an Event Image");
      return;
    }

    //todo if link does not start with "http" or "www." then prompt, please enter valid link
    if (
      this.state.eventTicketsLink &&
      !this.state.eventTicketsLink.startsWith("http")
    ) {
      this.setState({
        eventTicketsLink: "http://" + this.state.eventTicketsLink,
      });
    }

    if (
      this.state.eventMoreInfoLink &&
      !this.state.eventMoreInfoLink.startsWith("http")
    ) {
      this.setState({
        eventMoreInfoLink: "http://" + this.state.eventMoreInfoLink,
      });
    }

    console.log("_uploadEvent(): this.state.eventKey: ", this.state.eventKey);

    const eventDocRef = db.collection("events").doc(this.state.eventKey);

    const geoFirestoreDocumentRef = new GeoDocumentReference(eventDocRef);
    geoFirestoreDocumentRef
      .set(
        {
          //todo change back below hardcoded strings before releasing an update
          coordinates: new firebase.firestore.GeoPoint(53.799081, -9.524262),
          eventTimeStamp: this.state.eventTimeStamp,
          userFirebaseUid: firebase.auth().currentUser.uid,
          //userFirebaseUid: "n8zUw47uI1fEJfUU88xLdA2Krss2",
          userName: this.state.userName,
          //userName: "JCI Mayo",
          eventOrganiserImage: this.state.eventOrganiserImage,
          //eventOrganiserImage:
          // "https://firebasestorage.googleapis.com/v0/b/dapdap-77192.appspot.com/o/users%2Fn8zUw47uI1fEJfUU88xLdA2Krss2%2FuserProfile%2Ff871384b-290e-40fa-92ae-a0b1bad83fe5?alt=media&token=f8b41229-704c-41a5-acbc-bde3889cdea2",
          eventName: this.state.eventName,
          eventVenue: this.state.eventVenue,
          eventAddressLine1: this.state.eventAddressLine1,
          eventAddressLine2: this.state.eventAddressLine2,
          eventStartDate: this.state.eventStartDate,
          eventEndDate: this.state.eventEndDate,
          eventCategory: this.state.eventCategory,
          eventTicketsLink: this.state.eventTicketsLink,
          eventMoreInfoLink: this.state.eventMoreInfoLink,
          eventDescription: this.state.eventDescription,
          imageDownloadUrl: this.state.imageDownloadUrl,
          eventImageName: this.state.eventImageName,
          eventIsFeatured: this.state.eventIsFeatured,
          eventStatus: "published",
        },
        {
          merge: true,
        },
      )
      .then(() => {
        console.log(
          this.state.editMode
            ? "Event Update Success!"
            : "Event Created Success!",
        );
        this.setState({
          loading: false,
        });
        this.props.history.push("/userprofile");
      })
      .catch((error) => {
        console.log(
          this.state.editMode
            ? "Event Update failed error: "
            : "Event Creation failed",
          error,
        );
        this.setState({
          loading: false,
        });
      });
  };

  renderModeratorFields() {
    //  function that I will use to render fields that Only I can use
    //  and input such as userName, userFirebaseUid, & eventOrganiserImage
  }
}

const styles = {
  dateTimePicker: {
    width: 150,
    paddingTop: 20,
    paddingLeft: 20,
  },

  infoText: {
    fontWeight: "400",
    color: "#47525E",
  },

  link: {
    textDecoration: "none",
    color: "inherit",
  },

  textArea: {
    height: 200,
    borderWidth: 2,
    borderColor: "#e0e0e0",
    margin: 5,
    padding: 20,
  },
};
