import React from "react";
import * as firebase from "firebase";
import {Link} from "react-router-dom";
import ReactGA from "react-ga";

class LandingPage extends React.Component {
  // The component's Local state.
  state = {
    isSignedIn: false, // Local signed-in state.
  };

  // Configure FirebaseUI.
  uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: "popup",
    // We will display Google and Facebook as auth providers.
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      //firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
    ],
    signInSuccessUrl: "/completeprofile",
    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: (authResult, redirectUrl) => {
        console.log("authResult: ", authResult);
        console.log("isNewUser: ", authResult.additionalUserInfo.isNewUser);

        if (authResult.additionalUserInfo.isNewUser) {
          return true;
        } else {
          this.props.history.push("/userprofile");
          //if new user return, which will redirect to sign page 2
          // if user already exists, return false, user details will be displayed in top right corner
          return false;
        }
      },
    },
  };

  componentDidMount() {
    console.log("LandingPage componentDidMount");

    ReactGA.initialize("UA-69104407-4");

    // Listen to the Firebase Auth state and set the local state.
    this.unregisterAuthObserver = firebase.auth().onAuthStateChanged((user) => {
      let currentUser = user;
      this.setState({isSignedIn: !!user});
      console.log("Firebase current user: ", currentUser);
      if (this.state.isSignedIn) this.props.history.push("/userprofile");
    });
  }

  componentWillUnmount() {
    console.log("LandingPage componentWillUnmount");
    // Make sure we un-register Firebase observers when the component unmounts.
    this.unregisterAuthObserver();
  }

  render() {
    return (
      //if userSigned is not signed in show below notUserSignedin pages
      //Container
      <div
        style={{
          width: "100%",
          background: "#f2f2f2",
          minHeight: "100vh",
          paddingTop: "10px",
        }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            //margin: "3% 3%",
            background: "#fff",
            maxWidth: "440px",
            margin: "auto",
            paddingBottom: "30px",
            height: "100%",
            marginTop: "10px",
          }}>
          <div
            style={{
              // backgroundColor: "#20ff29",
              flex: 2,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              //border: "solid red",
            }}>
            {/*Main Heading Title & Description*/}
            <div
              style={{
                //border: "solid red",
                flex: 1,
                alignSelf: "stretch",
                paddingBottom: "2%",
              }}>
              <img
                style={{
                  width: "80%",
                  flex: 1,
                  margin: "auto",
                  //borderRadius: "60px",
                  //border: "1px solid red",
                }}
                alt={"event image"}
                src={require("../img/DapDapLogo4.png")}
              />
            </div>

            <div
              style={{
                //border: "solid red",
                flex: 1,
                alignSelf: "stretch",
                paddingBottom: "2%",
              }}>
              <h2> Free local event guide app </h2>
            </div>

            <div
              style={{
                //border: "solid red",
                flex: 1,
                fontWeight: "200",
                alignSelf: "stretch",
                paddingBottom: "2%",
              }}>
              <h2
                style={{
                  //border: "solid red",
                  flex: 1,
                  fontWeight: "200",
                  alignSelf: "stretch",
                  paddingBottom: "2%",
                }}>
                Download DapDap:
              </h2>
              <div
                style={{
                  //border: "solid red",
                  display: "flex",
                }}>
                <div>
                  <button
                    style={styles.button}
                    onClick={() => {
                      this.downloadAppdButtonClicked(
                        "https://apps.apple.com/ie/app/dapdap/id1239411135",
                      );
                    }}>
                    <img
                      style={{
                        width: "80%",
                        flex: 1,
                        margin: "auto",
                        //borderRadius: "60px",
                        //border: "1px solid red",
                      }}
                      alt={"event image"}
                      src={require("../img/appstoredownloadbadge.png")}
                    />
                  </button>
                </div>
                <div>
                  <button
                    style={styles.button}
                    onClick={() => {
                      this.downloadAppdButtonClicked(
                        "https://play.google.com/store/apps/details?id=com.andrewirwin.westport.dapdap&hl=en_IE",
                      );
                    }}>
                    <img
                      style={{
                        width: "80%",
                        flex: 1,
                        margin: "auto",
                        height: "80px",
                        marginTop: "-13px",
                        //borderRadius: "60px",
                        //border: "1px solid red",
                      }}
                      alt={"event image"}
                      src={require("../img/playstoredownloadbadge.png")}
                    />
                  </button>
                </div>
              </div>
            </div>

            <div
              style={{
                //border: "solid red",
                flex: 1,
                alignSelf: "stretch",
                fontWeight: "200",
                marginBottom: "20px",
              }}>
              <h2
                style={{
                  //border: "solid red",
                  flex: 1,
                  alignSelf: "stretch",
                  fontWeight: "200",
                  margin: "0",
                  padding: "15px",
                  paddingBottom: "40px",
                }}>
                Or
              </h2>
              <Link to={"/signin"} style={styles.link}>
                Create Free Event
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }

  downloadAppdButtonClicked(appStoreUrl) {
    window.location.replace(appStoreUrl);
  }
}

const styles = {
  link: {
    textDecoration: "none",
    padding: "15px 35px",
    background: "#f47332",
    color: "white",
    fontWeight: "800",
    borderRadius: "4px",
    marginTop: "20px",
  },

  button: {
    backgroundColor: "Transparent",
    backgroundRepeat: "noRepeat",
    border: "none",
    cursor: "pointer",
    overflow: "hidden",
    outline: "none",
  },
};

export default LandingPage;
