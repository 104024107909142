import React from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import * as firebase from "firebase";

//todo change below class name from LandingPage to
class SignInScreen extends React.Component {
  // The component's Local state.
  state = {
    isSignedIn: false, // Local signed-in state.
  };

  // Configure FirebaseUI.
  uiConfig = {
    signInFlow: "redirect",
    // We will display Google and Facebook as auth providers.
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
    ],
    tosUrl:
      "https://docs.google.com/document/d/1HiCZSV_gwxDOdHhlA3xphxcRWmvLDZX8Ua2qLHuqyPk/edit#heading=h.osamjkltm3ur",
    privacyPolicyUrl:
      "https://docs.google.com/document/d/1HiCZSV_gwxDOdHhlA3xphxcRWmvLDZX8Ua2qLHuqyPk/edit#heading=h.osamjkltm3ur",
    signInSuccessUrl: "/completeprofile",
    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: (authResult, redirectUrl) => {
        console.log("authResult: ", authResult);
        console.log("isNewUser: ", authResult.additionalUserInfo.isNewUser);
        if (authResult.additionalUserInfo.isNewUser) {
          return true;
        } else {
          this.props.history.push("/userprofile");
          //if new user return, which will redirect to sign page 2
          // if user already exists, return false, user details will be displayed in top right corner
          return false;
        }
      },
    },
  };

  // Listen to the Firebase Auth state and set the local state.
  componentDidMount() {
    console.log("LandingPage componentDidMount");
    this.unregisterAuthObserver = firebase.auth().onAuthStateChanged((user) => {
      let currentUser = user;
      this.setState({isSignedIn: !!user});
      console.log("Firebase current user: ", currentUser);
    });
  }

  componentWillUnmount() {
    console.log("LandingPage componentWillUnmount");
    // Make sure we un-register Firebase observers when the component unmounts.
    this.unregisterAuthObserver();
  }

  render() {
    return (
      //if userSigned is not signed in show below notUserSignedin pages
      //Container
      <div style={{display: "flex", flexDirection: "row"}}>
        {/*Left Section*/}
        <div
          style={{
            width: "100%",
            background: "#f2f2f2",
            minHeight: "100vh",
            paddingTop: "10px",
          }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              //margin: "3% 3%",
              background: "#fff",
              maxWidth: "440px",
              margin: "auto",
              paddingBottom: "30px",
              marginTop: "10px",
            }}>
            <div
              style={{
                //border: "solid red",
                width: "80%",
                margin: "auto",
                alignSelf: "stretch",
                paddingBottom: "2%",
                marginBottom: "20px",
                marginTop: "20px",
              }}>
              <img
                style={{
                  flex: 1,
                  width: "100%",
                  //borderRadius: "60px",
                  //border: "1px solid red",
                }}
                alt={"event image"}
                src={require("../img/DapDapLogo4.png")}
              />
            </div>

            <div
              style={{
                //border: "solid red",
                alignSelf: "stretch",
                paddingBottom: "2%",
                textAlign: "center",
                marginTop: "0",
              }}>
              <h2>Please sign in to create an event</h2>
              <div
                style={{
                  // backgroundColor: "#52ff5c",
                  flex: 1,

                  display: "flex",
                  flexDirection: "column",
                  //border: "solid red",
                }}>
                <StyledFirebaseAuth
                  uiConfig={this.uiConfig}
                  firebaseAuth={firebase.auth()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  link: {
    textDecoration: "none",
    color: "inherit",
  },
};

export default SignInScreen;
