import React from "react";
import {View} from "react-native";

const Row = (props) => {
  return (
    <View style={[styles.containerStyle, props.style]}>{props.children}</View>
  );
};

const styles = {
  containerStyle: {
    flexDirection: "row",
    paddingLeft: 5,
    paddingRight: 5,
  },
};

export {Row};
