export const EMAIL_CHANGED = "EMAIL_CHANGED";
export const PASSWORD_CHANGED = "PASSWORD_CHANGED";
export const CONFIRM_PASSWORD_CHANGED = "CONFIRM_PASSWORD_CHANGED";
export const LOGIN_USER_ANONYMOUSLY = "LOGIN_USER_ANONYMOUSLY";
export const CREATE_USER_ACCOUNT = "CREATE_USER_ACCOUNT";
export const CREATE_USER_ACCOUNT_SUCCESS = "CREATE_USER_ACCOUNT_SUCCESS";
export const CREATE_USER_ACCOUNT_FAIL = "CREATE_USER_ACCOUNT_FAIL";
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAIL = "LOGIN_USER_FAIL";
export const CLEAR_DATA = "CLEAR_DATA";
