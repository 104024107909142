import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import "semantic-ui-css/semantic.min.css";

import * as serviceWorker from "./serviceWorker";
//Redux
import {Provider} from "react-redux";
import {createStore, applyMiddleware} from "redux";
import * as Sentry from "@sentry/browser";

import thunk from "redux-thunk";
import reducers from "./reducers";

Sentry.init({
  dsn: "https://fd1daf939ef744c9bd3acfdf912ec3db@sentry.io/1435785",
  release: "react@1.3.16",
});

const store = createStore(reducers, applyMiddleware(thunk));

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
