/**
 *
 * @format
 * @flow
 */

import React from "react";
import * as firebase from "firebase";
import * as Sentry from "@sentry/browser";
import {
  Form,
  Button,
  TextArea,
  Dimmer,
  Loader,
  Segment,
  Confirm,
} from "semantic-ui-react";

import EventCard from "../common/EventCard/EventCard";
import {FiLogOut} from "react-icons/fi";
import moment from "moment";

import {connect} from "react-redux";
import {
  setFieldInDB,
  userNameChanged,
  userProfileDescriptionChanged,
} from "../actions/UserProfileActions";

import getFirestoreDB from "../firebase/firestore/getFirestoreDb";
const db = getFirestoreDB();
const usersCollectionRef = db.collection("users");

const TAG = "UserProfileScreen: ";

class UserProfileScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      eventOrganizerImage: "",
      userName: "",
      events: "",
      loading: false,
      loadingMessage: "Please Wait",
      showConfirmDeleteModal: false,
      showConfirmLogOutModal: false,
    };

    this.getUserEvents = this.getUserEvents.bind(this);
  }

  componentDidMount() {
    console.log(TAG + "componentDidMount");
    this.unregisterAuthObserver = firebase.auth().onAuthStateChanged((user) => {
      let currentUser = user;
      this.setState({isSignedIn: !!user, currentUser: user});
      console.log("Firebase current user: ", currentUser);

      if (this.state.isSignedIn) {
        //todo make sure below code is not hard coded
        this.getUserDocfromDB(currentUser.uid);

        //todo check below code is not hardcoded
        this.getUserEvents(currentUser.uid);
      } else {
        this.props.history.push("/");
      }
    });
  }

  componentWillUnmount() {
    console.log(TAG + "componentWillUnmount");
    // Make sure we un-register Firebase observers when the component unmounts.
    this.unregisterAuthObserver();
  }

  render() {
    return (
      //Container
      <Dimmer.Dimmable as={Segment} dimmed={this.state.loading}>
        <Dimmer active={this.state.loading} inverted>
          <Loader>{this.state.loadingMessage}</Loader>
        </Dimmer>
        <Confirm
          open={this.state.showConfirmDeleteModal}
          onCancel={this.confirmDeleteModalOnCancel}
          onConfirm={this.confirmDeleteModalOnConfirm}
          content={"Are you sure you want to delete this event"}
          confirmButton={"Yes"}
        />

        <Confirm
          open={this.state.showConfirmLogOutModal}
          onCancel={this.confirmLogOutModalOnCancel}
          onConfirm={this.confirmLogoutOnConfirm}
          content={"Are you sure you want to logout"}
          confirmButton={"Yes"}
        />

        <div
          style={{
            width: "100%",
            background: "#f2f2f2",
            minHeight: "100vh",
            paddingTop: "10px",
          }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              //margin: "3% 3%",
              background: "#fff",
              maxWidth: "440px",
              margin: "auto",
              paddingBottom: "30px",
              marginTop: "10px",
              padding: "15px 30px",
            }}>
            <div style={{alignSelf: "flex-end"}}>
              <FiLogOut
                size={20}
                style={{paddingStart: 10, paddingEnd: 10}}
                color="#9E9E9E"
                onClick={this.showConfirmLogOutModal}
              />
            </div>

            {/*UserProfile info*/}
            <div
              style={{
                // border: "solid red 1px",
                marginTop: "5%",
                display: "flex",
                flexDirection: "column",
              }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  //border: "1px solid red",
                }}>
                {/*todo add in more checks and validations to file input*/}
                <label htmlFor="profileImage">
                  <img
                    style={{
                      height: "128px",
                      width: "128px",
                      borderRadius: "60px",
                      //border: "1px solid red",
                    }}
                    alt={"user profile"}
                    src={
                      this.state.eventOrganiserImage
                        ? this.state.eventOrganiserImage
                        : require("../img/userprofileplaceholderimage.png")
                    }
                  />
                  <input
                    type="file"
                    name="profileImage"
                    id="profileImage"
                    accept={"image/*"}
                    onChange={this.selectImageHandler}
                    style={{display: "none", height: "30px"}}
                  />
                </label>
                {/*User name and location*/}
                <div
                  style={{
                    //border: "1px solid #dfe1e5",
                    borderRadius: "5%",
                    // backgroundColor: "#f1f1f1",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    padding: "0% 2%",
                    marginLeft: "2%",
                    marginTop: "10%",
                    marginBottom: "10%",
                  }}>
                  <input
                    type="text"
                    style={{
                      fontSize: "20",
                      fontStyle: "light",
                      height: "30px",
                      marginTop: "10px",
                      borderRadius: "4px",
                      border: "1px solid silver",
                    }}
                    maxLength={35}
                    //returnKeyType="done"
                    placeholder="Please Enter Profile Name"
                    onChange={(event) => this.onUserNameChanged(event)}
                    onBlur={() => this.editUserName()}
                    value={this.state.userName}
                  />
                </div>
              </div>

              <div style={{paddingTop: 30}}>
                <label>
                  Profile Description
                  <Form style={{paddingTop: 10}}>
                    <TextArea
                      style={{
                        fontStyle: "light",
                        height: "100px",
                        marginTop: "10px",
                        width: "100%",
                        borderRadius: "4px",
                        border: "1px solid silver",
                        fontSize: "16px",
                      }}
                      placeholder="Tell us about yourself here"
                      maxLength={150}
                      onChange={(event) =>
                        this.onUserProfileDescriptionChanged(event)
                      }
                      onBlur={() => this.editUserProfileDescription()}
                      value={this.state.userProfileDescription}
                    />
                  </Form>
                </label>
              </div>
            </div>

            <Button
              style={{
                textDecoration: "none",
                padding: "15px 35px",
                background: "#f47332",
                color: "#fff",
                fontWeight: "500",
                borderRadius: "4px",
                marginTop: "20px",
                marginBottom: "40px",
                fontSize: "20px",
              }}
              onClick={this.openEventFormCreate}>
              Create Event
            </Button>

            {this.state.events &&
              this.state.events.map((item, index) => (
                <EventCard
                  key={item.eventKey}
                  event={item}
                  openEditEvent={() => this.openEditEvent(item)}
                  openDeleteEventAlert={() => this.openDeleteEventAlert(item)}
                />
              ))}
          </div>
        </div>
      </Dimmer.Dimmable>
    );
  }

  onUserNameChanged(event) {
    this.setState({userName: event.target.value});
  }

  editUserName = () => {
    console.log(TAG + "editUserName text: ", this.state.userName);

    if (!this.state.userName.length) {
      alert("Please enter a user name");
      return;
    }

    this.setFieldInDB(
      usersCollectionRef,
      firebase.auth().currentUser.uid,
      "userName",
      this.state.userName,
    );
  };

  onUserProfileDescriptionChanged(event) {
    this.setState({userProfileDescription: event.target.value});
  }

  editUserProfileDescription = () => {
    console.log(
      TAG + "editUserProfileDescription text: ",
      this.state.userProfileDescription,
    );

    if (!this.state.userProfileDescription.length) {
      alert("Please enter a profile description");
      return;
    }
    this.setFieldInDB(
      usersCollectionRef,
      firebase.auth().currentUser.uid,
      "userProfileDescription",
      this.state.userProfileDescription,
    );
  };

  //todo look at replacing these methods (updateFieldInDB, setFieldInDB) to one from redux actions
  setFieldInDB = (CollectionRef, docRef, fieldKey, fieldValue) => {
    CollectionRef.doc(docRef)
      .set(
        {
          [fieldKey]: fieldValue,
          userFirebaseUid: docRef,
        },
        {merge: true},
      )
      .then(() => {
        console.log(TAG + "setFieldInDB: Document successfully written!");
        this.setState({userDocExists: true});
      })
      .catch(function(error) {
        console.error(TAG + "setFieldInDB Error writing document: ", error);
      });
  };

  getUserDocfromDB = (userFirebaseUid) => {
    console.log(TAG + "getDocfromDB fired");

    this.unsubscribe && this.unsubscribe();
    this.unsubscribe = db
      .collection("users")
      .doc(userFirebaseUid)
      .onSnapshot(
        (doc) => {
          if (!doc.exists) {
            console.log(TAG + "User Document does not exist");
            return;
          }
          console.log(TAG + "User Document Exists! data: ", doc.data());
          this.setState({
            userDocExists: true,
            userName: doc.data().userName
              ? doc.data().userName
              : this.state.userName,
            userProfileDescription: doc.data().userProfileDescription
              ? doc.data().userProfileDescription
              : this.state.userProfileDescription,
            eventOrganiserImage: doc.data().eventOrganiserImage
              ? doc.data().eventOrganiserImage
              : this.state.eventOrganiserImage,
          });

          Sentry.configureScope((scope) => {
            scope.setUser({id: userFirebaseUid, username: doc.data().userName});
          });
        },
        (error) => {
          console.log(TAG + "getDocfromDB failed error: ", error);
        },
      );
  };

  getUserEvents = async (userFirebaseUid) => {
    const userEventsCollectionRef = db
      .collection("events")
      .where("d.userFirebaseUid", "==", userFirebaseUid);

    //Todo update below line of code to include, .then and error catching
    //todo change below get function with .onSnapshot function, and move into redux
    let userEventDocs = await userEventsCollectionRef.get();

    let events = await this.queryEvents(userEventDocs);

    //todo write try catch if events is array is empty display no events

    this.setState({
      events,
      loading: false,
    });

    console.log(TAG + "getUserEvents end");
  };

  queryEvents = async (userEventDocs) => {
    const fetchedEventDocs = [];

    userEventDocs.forEach(function(doc) {
      console.log(TAG + "doc received, doc :", doc.data());

      const {
        userName,
        eventOrganiserImage,
        imageDownloadUrl,
        eventImageName,
        eventName,
        eventStartDate,
        eventEndDate,
        eventVenue,
        eventAddressLine1,
        eventAddressLine2,
        eventCategory,
        eventTicketsLink,
        eventMoreInfoLink,
        eventDescription,
        userFirebaseUid,
        eventStatus,
        eventIsFeatured,
        eventTimeStamp,
      } = doc.data().d;

      fetchedEventDocs.push({
        eventKey: doc.id,
        //doc, //DocumentSnapshot
        userName,
        eventOrganiserImage,
        imageDownloadUrl,
        eventImageName,
        eventName,
        eventStartDate,
        eventEndDate,
        eventVenue,
        eventAddressLine1,
        eventAddressLine2,
        eventCategory,
        eventTicketsLink,
        eventMoreInfoLink,
        eventDescription,
        userFirebaseUid,
        eventStatus,
        eventIsFeatured,
        eventTimeStamp,
      });
    }); //foreach end

    fetchedEventDocs.sort(function(left, right) {
      if (left.eventTimeStamp !== undefined) {
        return moment
          .utc(right.eventTimeStamp)
          .diff(moment.utc(left.eventTimeStamp));
      }
    });

    return fetchedEventDocs;
  };

  selectImageHandler = (event) => {
    let file = event.target.files[0];
    console.log(TAG + "selectedFile: ", this.state.selectedFile);

    try {
      this.uploadFile(file);
    } catch (error) {
      console.error("selectImageHandler error: " + error);
    }
  };

  uploadFile = (file) => {
    this.setState({loading: true});
    const storageRef = firebase
      .storage()
      .ref(
        "users/" +
          firebase.auth().currentUser.uid +
          "/" +
          "userProfile/" +
          file.name,
      );
    let task = storageRef.put(file);

    task.on(
      "state_changed",

      function progress(snapshot) {},

      (error) => {
        console.log("Fire storage upload error: ", error);
        this.setState({loading: false});
      },

      () => {
        console.log("Fire storage upload Successful!");

        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        task.snapshot.ref.getDownloadURL().then((downloadURL) => {
          console.log("File available at", downloadURL);
          this.setState({
            eventOrganiserImage: downloadURL,
            didImageUploadToStorage: true,
          });

          this.editUserProfileImage();
        });
      },
    );
  };

  editUserProfileImage = async () => {
    console.log(TAG + "editUserProfileImage FIRED!");
    console.log(
      TAG + "editUserProfileImage text: ",
      this.state.eventOrganiserImage,
    );
    //todo re implement check to see if eventOrganiserImage exists
    await this.props.setFieldInDB(
      usersCollectionRef,
      firebase.auth().currentUser.uid,
      "eventOrganiserImage",
      this.state.eventOrganiserImage,
    );
    this.setState({loading: false});
  };

  openEventFormCreate = () => {
    console.log(TAG + "openEventFormCreate Called");

    if (!this.state.userName.length || !this.state.eventOrganiserImage.length) {
      alert(
        "Please create profile name, profile image, and profile description",
      );
      return;
    }

    this.props.history.push("/eventform", {
      userName: this.state.userName,
      eventOrganiserImage: this.state.eventOrganiserImage,
    });
  };

  openEditEvent = (event) => {
    console.log(TAG + "openEditEvent Called");

    if (!this.state.userName.length || !this.state.eventOrganiserImage.length) {
      alert("Please create user name & profile image");
      return;
    }
    let serializedEvent = JSON.stringify(event);
    console.log(TAG + "serializedEvent" + serializedEvent);

    this.props.history.push("/eventform", {
      coordinates: new firebase.firestore.GeoPoint(53.799081, -9.524262),
      userFirebaseUid: event.userFirebaseUid,
      eventTimeStamp: event.eventTimeStamp,
      eventName: event.eventName,
      eventVenue: event.eventVenue,
      eventAddressLine1: event.eventAddressLine1,
      eventAddressLine2: event.eventAddressLine2,
      eventStartDate: event.eventStartDate,
      eventEndDate: event.eventEndDate,
      eventCategory: event.eventCategory,
      eventTicketsLink: event.eventTicketsLink,
      eventMoreInfoLink: event.eventMoreInfoLink,
      eventDescription: event.eventDescription,
      imageDownloadUrl: event.imageDownloadUrl,
      eventImageName: event.eventImageName,
      eventIsFeatured: event.eventIsFeatured,
      eventStatus: "published",
      eventKey: event.eventKey,
      userName: this.state.userName,
      eventOrganiserImage: this.state.eventOrganiserImage,
      editMode: true,
    });
  };

  openEventDetail = (event) => {
    this.props.history.push("/eventdetail", {
      event: event,
    });
  };

  openDeleteEventAlert = (event) => {
    console.log(
      TAG + "deleteEvent button pressed event.eventKey: " + event.eventKey,
    );
    this.showConfirmDeleteModal(event);
  };

  showConfirmDeleteModal = (event) => {
    this.setState({showConfirmDeleteModal: true, eventToBeDeleted: event});
  };

  confirmDeleteModalOnCancel = () => {
    this.setState({showConfirmDeleteModal: false});
  };

  confirmDeleteModalOnConfirm = () => {
    this.setState({showConfirmDeleteModal: false});
    console.log(
      TAG +
        "onConfirmClicked event key: " +
        this.state.eventToBeDeleted.eventKey,
    );
    this.deleteEvent(this.state.eventToBeDeleted);
  };

  deleteEvent = (event) => {
    console.log(TAG + "deleteEvent fired" + " event.eventKey" + event.eventKey);

    let eventPhotoStorageRef = firebase
      .storage()
      .ref(
        "/users/" +
          firebase.auth().currentUser.uid +
          "/userEvents/" +
          event.eventKey +
          "/" +
          event.eventImageName,
      );

    eventPhotoStorageRef
      .getDownloadURL()
      .then((url) => {})
      .catch((error) => {
        console.log(
          "deleteEvent error could find eventPhoto in Storage: " + error,
        );
        if (error.code === "storage/object-not-found") {
          eventPhotoStorageRef = firebase
            .storage()
            .ref(
              "/users/" +
                firebase.auth().currentUser.uid +
                "/userEvents/" +
                event.eventImageName,
            );
        }
      });

    db.collection("events")
      .doc(event.eventKey)
      .delete()
      .then(
        () => {
          console.log(TAG + "Delete Event Success!");

          eventPhotoStorageRef
            .delete()
            .then(() => {
              console.log(TAG + "eventPhoto delete success !");
              this.removeEventFromEventStateArray(event.eventKey);
            })
            .catch(function(error) {
              console.log(TAG + "Delete eventPhoto failed! Error: ", error);
              Sentry.captureException(error);
            });
        },
        (error) => {
          console.log(TAG + "Delete EventCard Failed! Error: " + error);
        },
      );
  };

  removeEventFromEventStateArray = (eventKey) => {
    this.setState((state) => {
      const events = state.events.filter(
        (event) => event.eventKey !== eventKey,
      );
      return {events};
    });
  };

  showConfirmLogOutModal = () => this.setState({showConfirmLogOutModal: true});

  confirmLogoutOnConfirm = () => {
    this.setState({showConfirmLogOutModal: false});
    this.logout();
  };

  confirmLogOutModalOnCancel = () =>
    this.setState({showConfirmLogOutModal: false});

  logout = async () => {
    try {
      console.log(TAG + "Log out Clicked");
      await firebase.auth().signOut();
    } catch (error) {
      console.log(TAG + error);
    }
  };
}

const styles = {
  link: {
    textDecoration: "none",
    color: "inherit",
  },

  eventPromoterName: {
    marginLeft: 10,
    flex: 1,
    flexWrap: "wrap",
    height: "30px",
    // backgroundColor: '#20ff29',
  },

  eventPromoterDescription: {
    marginLeft: 5,
    marginRight: 5,
    marginTop: 20,
    marginBottom: 10,
    flex: 1,
    width: "100%",
    borderRadius: "4px",
    // backgroundColor: '#4a89ff',
  },
};

const mapStateToProps = ({user}) => {
  const {userName, userProfileDescription, success, loading, error} = user;
  return {userName, userProfileDescription, success, loading, error};
};

export default connect(
  mapStateToProps,
  {
    userNameChanged,
    setFieldInDB,
    userProfileDescriptionChanged,
  },
)(UserProfileScreen);
