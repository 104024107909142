import React from "react";

const CardSection = (props) => {
  return <div style={props.style}>{props.children}</div>;
};

const styles = {
  containerStyle: {
    borderBottomWidth: 1,
    paddingRight: 5,
    paddingLeft: 5,
    paddingTop: 25,
    paddingBottom: 25,
    backgroundColor: "#fff",
    justifyContent: "flex-start",
    borderColor: "#ddd",
  },
};

export {CardSection};
