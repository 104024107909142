import React from "react";
import * as firebase from "firebase";
import getFirestoreDb from "../firebase/firestore/getFirestoreDb";
import {
  Form,
  TextArea,
  Dimmer,
  Segment,
  Loader,
  Button,
} from "semantic-ui-react";

import {connect} from "react-redux";
import {
  setFieldInDB,
  userNameChanged,
  userProfileDescriptionChanged,
} from "../actions/UserProfileActions";

const db = getFirestoreDb();
const usersCollectionRef = db.collection("users");

const TAG = "CompleteUserProfileScreen ";

class CompleteUserProfileScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      eventOrganiserImage: "",
      selectedFile: "",
      loading: false,
      loadingMessage: "Please Wait",
    };
  }

  componentDidMount() {
    console.log(TAG + "componentDidMount");
    this.unregisterAuthObserver = firebase.auth().onAuthStateChanged((user) => {
      let currentUser = user;
      this.setState({isSignedIn: !!user});
      console.log("Firebase current user: ", currentUser);
    });
  }

  componentWillUnmount() {
    console.log(TAG + "componentWillUnmount");
    this.unregisterAuthObserver();
  }

  render() {
    const {userName, userProfileDescription} = this.props;
    const isSubmitButtonEnabled =
      userName && this.state.didImageUploadToStorage && userProfileDescription;
    return (
      //Container
      <div
        style={{
          width: "100%",
          background: "#f2f2f2",
          minHeight: "100vh",
          paddingTop: "10px",
        }}>
        <Dimmer.Dimmable as={Segment} dimmed={this.state.loading}>
          <Dimmer active={this.state.loading} inverted>
            <Loader>{this.state.loadingMessage}</Loader>
          </Dimmer>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              //margin: "3% 3%",
              background: "#fff",
              maxWidth: "440px",
              margin: "auto",
              paddingBottom: "30px",
              marginTop: "10px",
              padding: "15px 30px",
            }}>
            <h2
              style={{
                color: "#47525E",
                fontSize: "20px",
                fontFamily: "",
                fontWeight: "500",
                textAlign: "center",
                padding: "10px",
              }}>
              Welcome to DapDap
              {/*todo make sure below firebase code is uncommented*/}
              {this.state.isSignedIn
                ? ", " + firebase.auth().currentUser.displayName.split(" ")[0]
                : ""}
              {"!"}
              <br />
              let's make your profile
            </h2>
            {/*userName Input*/}
            <div
              style={{
                //flex: "1",
                flexDirection: "row",
                justifyContent: "center",
                // alignSelf: "stretch",
              }}>
              <label>
                Profile Name{" "}
                <input
                  type="text"
                  maxLength={20}
                  value={this.props.userName}
                  onChange={(event) => this.onUserNameChanged(event)}
                  style={{
                    // borderRadius: "5%",
                    fontSize: "20",
                    fontStyle: "light",
                    height: "30px",
                    marginTop: "10px",
                    borderRadius: "4px",
                    border: "1px solid silver",
                  }}
                />
              </label>
            </div>

            <div
              style={{
                marginTop: "5%",
                display: "flex",
                flexDirection: "row",
              }}>
              <label>Profile Image: </label>

              {/*todo if user profile image is null replace with placeholder image*/}
              {/*todo add in more checks and validations to file input*/}
              <label htmlFor="profileImage">
                <img
                  style={{
                    height: "128px",
                    width: "128px",
                    borderRadius: "60px",
                  }}
                  alt={"user profile image"}
                  src={
                    this.state.eventOrganiserImage
                      ? this.state.eventOrganiserImage
                      : require("../img/addPicturePlaceHolder.png")
                  }
                />
                <input
                  type="file"
                  name="profileImage"
                  id="profileImage"
                  accept={"image/*"}
                  onChange={this.selectImageHandler}
                  style={{display: "none"}}
                />
              </label>
            </div>

            <div style={{paddingTop: 30}}>
              <label>
                Profile Description
                <Form style={{paddingTop: 10}}>
                  <TextArea
                    style={{
                      fontStyle: "light",
                      height: "100px",
                      marginTop: "10px",
                      width: "100%",
                      borderRadius: "4px",
                      border: "1px solid silver",
                      fontSize: "16px",
                    }}
                    //placeholder="Tell us about yourself here"
                    value={this.props.userProfileDescription}
                    maxLength={150}
                    onChange={(event) =>
                      this.onUserProfileDescriptionChanged(event)
                    }
                  />
                </Form>
              </label>
            </div>

            {this.renderError()}

            <Button
              style={{
                // border: "solid red",
                textDecoration: "none",
                padding: "15px 35px",
                background: "#f47332",
                color: "white",
                fontWeight: "500",
                borderRadius: "4px",
                marginTop: "20px",
                fontSize: "20px",
              }}
              disabled={!isSubmitButtonEnabled}
              onClick={this.onSubmitButtonPressed}>
              Create Profile
            </Button>
          </div>
        </Dimmer.Dimmable>
      </div>
    );
  }

  renderError() {
    if (this.props.error) {
      return (
        <div>
          style=
          {{
            flex: 1,
            backgroundColor: "#fff",
            paddingBottom: 10,
            paddingTop: 10,
          }}
          ><h2 style={styles.errorTextStyle}>{this.props.error}</h2>
        </div>
      );
    }
  }

  onUserNameChanged(event) {
    this.props.userNameChanged(event.target.value);
  }

  selectImageHandler = (event) => {
    let file = event.target.files[0];
    console.log(TAG + "selectedFile: ", this.state.selectedFile);

    try {
      this.uploadFile(file);
    } catch (error) {
      console.error("selectImageHandler error: " + error);
    }
  };

  uploadFile = (file) => {
    this.setState({
      loading: true,
      loadingMessage: "Uploading Picture, Please Wait",
    });
    const storageRef = firebase
      .storage()
      .ref(
        "users/" +
          firebase.auth().currentUser.uid +
          "/" +
          "userProfile/" +
          file.name,
      );
    let task = storageRef.put(file);

    task.on(
      "state_changed",

      function progress(snapshot) {},

      (error) => {
        console.log("Fire storage upload error: ", error);
        this.setState({loading: false});
      },

      () => {
        console.log("Fire storage upload Successful!");

        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        task.snapshot.ref.getDownloadURL().then((downloadURL) => {
          console.log("File available at", downloadURL);
          this.setState({
            eventOrganiserImage: downloadURL,
            didImageUploadToStorage: true,
          });

          this.editUserProfileImage();
        });
      },
    );
  };

  editUserProfileImage = async () => {
    console.log(TAG + "editUserProfileImage FIRED!");
    console.log(
      TAG + "editUserProfileImage text: ",
      this.state.eventOrganiserImage,
    );
    //todo re implement check to see if eventOrganiserImage exists
    await this.props.setFieldInDB(
      usersCollectionRef,
      firebase.auth().currentUser.uid,
      "eventOrganiserImage",
      this.state.eventOrganiserImage,
    );

    this.setState({loading: false});
  };

  onUserProfileDescriptionChanged(event) {
    this.props.userProfileDescriptionChanged(event.target.value);
  }

  onSubmitButtonPressed = async (event) => {
    console.log(TAG + "submit button pressed");

    try {
      await this.onSubmitUserName();
      await this.onSubmitUserProfileDescription();
    } catch (e) {
      console.log(e);
    }
    //todo if fields are empty disable submit button

    if (
      this.props.userName &&
      this.props.userProfileDescription &&
      this.state.didImageUploadToStorage &&
      !this.props.error
    )
      this.props.history.push("/userprofile");
    event.preventDefault();
  };

  onSubmitUserName = async () => {
    await this.props.setFieldInDB(
      usersCollectionRef,
      firebase.auth().currentUser.uid,
      "userName",
      this.props.userName.trim(),
    );
  };

  onSubmitUserProfileDescription = async () => {
    await this.props.setFieldInDB(
      usersCollectionRef,
      firebase.auth().currentUser.uid,
      "userProfileDescription",
      this.props.userProfileDescription.trim(),
    );
  };
}

const styles = {
  link: {
    textDecoration: "none",
    color: "inherit",
  },

  errorTextStyle: {
    fontSize: 20,
    alignSelf: "center",
    color: "red",
  },
};

const mapStateToProps = ({user}) => {
  const {userName, userProfileDescription, success, loading, error} = user;
  return {userName, userProfileDescription, success, loading, error};
};

export default connect(
  mapStateToProps,
  {
    userNameChanged,
    setFieldInDB,
    userProfileDescriptionChanged,
  },
)(CompleteUserProfileScreen);
