import React, {Component} from "react";
import {StyleSheet, View, Modal, ActivityIndicator, Text} from "react-native";

const LoadingModal = (props) => {
  const {loading, ...attributes} = props;

  return (
    <Modal visible={loading} transparent={true} animationType={"none"}>
      <View style={styles.modalBackground}>
        <View style={styles.activityIndicatorWrapper}>
          <Text>Please Wait</Text>
          <ActivityIndicator animating={loading} />
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modalBackground: {
    flex: 1,
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "space-around",
    backgroundColor: "#00000040",
  },
  activityIndicatorWrapper: {
    backgroundColor: "#FFFFFF",
    height: 150,
    width: 150,
    borderRadius: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
});

export {LoadingModal};
